@use '~styles/variables/colors';

.searchPanel {
    input:not([type='checkbox']) {
        padding-left: 4rem !important;
    }

    &--small {
        margin-top: -2.5rem;
        padding-top: 2.5rem;
        background-color: colors.$gray-gallery;

        form {
            background-color: colors.$gray-gallery;
        }
    }

    &--big {
        input:not([type='checkbox']) {
            max-width: 55rem;
        }
    }
}

.centred {
    justify-content: center;
}

.smallSearchWrapper {
    width: 100% !important;

    >div {
        width: 100% !important;
    }

    input {
        max-width: unset !important;
    }
}

.searchPanelTitle {
    text-align: center;
    font-size: 3.4rem;
}

.resultsSearchIcon {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.searchPanelCheckbox {
    padding-left: 0;
    padding-right: 0;

    input[type='checkbox'] {
        margin-left: 0;
        margin-right: 1rem;
    }
}

.typeahead {
    i {
        font-size: 2rem;
        width: 2rem;
        height: 2rem;
    }

    a {
        font-size: 1.6rem;
        line-height: 2.1rem;
        padding: 0.4rem 1rem 0.8rem;
        color: colors.$blue-science;

        div {
            white-space: break-spaces;
        }

        mark {
            color: colors.$blue-science;
            background-color: colors.$yellow-selection;
            font-weight: 600;
            padding: 0;
            margin: 0;
        }
    }

    a:hover {
        text-decoration: none !important;
        color: colors.$white;
        background-color: colors.$blue-science;

        mark {
            background-color: colors.$white;
        }
    }

    div::before {
        background-size: contain;
        width: 2.7rem;
        height: 2.7rem;
        top: 0rem;
    }

    div:nth-child(2) {
        max-height: unset !important;
    }
}

.searchIcon {
    display: block;
    position: absolute;
    z-index: 4;
    font-size: 2rem;
    color: colors.$blue-science;
    top: calc(50% - 0.5rem);
    transform: translateY(-50%);
    left: 1.75rem;

    &+div input {
        padding-left: 4rem !important;
        max-width: 55rem;
    }
}