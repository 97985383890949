@use "~styles/variables/colors";

.formWrapper {
  border: 0;
  box-shadow: none;
  padding: 12px;
  border-right: 5px;
  background-color: colors.$gray-wildsand;
  border-radius: 3px;
}

.form {
  background-color: colors.$white;
  padding: 1.5rem 2rem;
}

.formTitle {
  margin: 20px 0;
  font-weight: 600;
  line-height: 1.1em;
}

.formFieldLabel {
  font-size: 1.6rem;
}

.formInput {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  color: #868686;
  background-color: colors.$white;
  background-image: none !important;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.formLinks {
  display: block;
  font-size: 1.3rem;
  border: 0;
  outline: 0;
  background-color: colors.$white;
  padding: 0;
  color: colors.$blue-science;
}

.formLinks:hover {
  text-decoration: underline;
}
