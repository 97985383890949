@use 'styles/variables/colors';

.customModal {
  width: 900px;
  max-width: 900px;
}

.formDescription {
  color: colors.$gray-mineshaft;
  margin: 0 0 10px;
}

.horizontal {
  color: rgba(0, 0, 0, 0.5);
  margin: 1.6rem 0;
}
