@use '~styles/variables/colors' as *;
.create {
    background-image: url('./icon-mendeleiev-add.png');
    background-size: 100%;
}

.text {
    span {
        font-size: 2.6rem;
        font-weight: 600;
    }
}

.button {
    padding: 0;
    border: 0;
    background: unset;
    color: $blue-science;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
}
.button:hover{
    color: #000;
}
