@use '~styles/variables/colors';
.background {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 62px;
    height: 57px;
    margin-right: 1rem;
    color: colors.$gray-wildsand;
    > span {
        max-width: 100%;
        font-weight: 600;
        line-height: 1.4em;
        text-transform: uppercase;
        text-align: center;
        white-space: nowrap;
        font-size: 1.8rem;
        color: #fff;
    }
}
.name {
    font-weight: 600;
    margin: 0;
    text-transform: capitalize;
}
.type {
    font-size: 1.4rem;
    line-height: 1.4rem;
    font-weight: 500;
}
.questionsNum {
    font-size: 1.4rem;
    font-weight: 400;
}

.waitingApproval {
    font-size: 1.4rem;
    font-weight: 400;
    color:  colors.$green-salad;
}

.link,
.link:hover,
.link:active,
.link:visited,
.link:focus {
    text-decoration: none;
    color: inherit !important;
}
