@use '~styles/utils/mixins';
.indexBg {
    background: #fff url(./bg-shadow.png) repeat-x top center;
    min-height: 60vw;
};
.wrapper{
    padding: 4rem 0 15rem;
    @include mixins.respond-to('lg'){
        padding: 4rem 0;
    }
}