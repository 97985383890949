.option {
    text-decoration: none !important;
    padding-left: 3.7rem;
    position: relative;
    strong {
        margin-right: 1rem;
    }
}

.option:hover {
    text-decoration: none;
}

.option::before {
    content: '';
    position: absolute;
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    left: 0;
    width: 2.7rem;
    height: 2.7rem;
    top: 0.2rem;
    margin-right: 2rem;
}

.optionText{
    text-decoration: none !important;
    padding-left: 3.7rem !important;
    position: relative;
    strong {
        margin-right: 1rem;
    } 
    i{
        font-size: 2.2rem !important;
    }  
}
