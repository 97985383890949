.download {
    padding: 0 !important;
    position: absolute !important;
    width: 40px !important;
    height: 40px !important;
    right: 30px;
    top: 0;
    background-color: transparent !important;
    z-index: 10 !important;
    a{
        min-width: 40px;
        height: 40px;
        display: inline-block;
        justify-content: center;
        align-items: center;
    }
}
