@use '~styles/variables/colors';

.infoTextWrapper {
    background: colors.$gray-concrete;
    padding: 1rem 2rem;
    margin-bottom: 2rem;

    h3{
        margin: 0 0 1rem 0;
        font-size: 2rem !important;
        font-weight: 600;
    }

    p{
        font-size: 1.4rem;
        margin: 0 0 1rem;
    }
}

.helpInfoText {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    color: #737373;
    font-size: 1.3rem;
}

