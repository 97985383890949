@use '~styles/variables/colors';

.background {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 62px;
    height: 57px;
    margin-right: 1rem;
    color: colors.$gray-wildsand;
    > span {
        max-width: 100%;
        font-weight: 600;
        line-height: 1.4em;
        text-transform: uppercase;
        text-align: center;
        white-space: nowrap;
        font-size: 1.8rem;
        color: #fff;
    }
}
.backgroundBig {
    position: relative;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 109px;
    height: 100px;
    color: colors.$gray-wildsand;
    >span:first-child{
        font-size: 1.1rem;
    }
}
.number {
    position: absolute;
    right: 1rem;
    bottom: 0.7rem;
    font-size: 2.2rem;
}

.core {
    background-image: url('./images/icon-mendeleiev-core.png');
}

.module {
    background-image: url('./images/icon-mendeleiev-module.png');
}

.standalone {
    background-image: url('./images/icon-mendeleiev-standalone.png');
}

.previous {
    background-image: url('./images/icon-mendeleiev-previous.png');
}

.cat {
    background-image: url('./images/icon-mendeleiev-CAT.png');
}

.catshort {
    background-image: url('./images/icon-mendeleiev-catshort.png');
}

.itemlist {
    // Not sure - should be checked!
    background-image: url('./images/icon-mendeleiev-previous.png');
}

.draft {
    background-image: url('./images/icon-mendeleiev-draft.png');
}

.shortform {
    background-image: url('./images/icon-mendeleiev-shortform.png');
}

.smallCode {
    font-size: 1.1rem !important;
    font-weight: 600;
}

.code{
    font-size: 1.3rem !important;
    font-weight: 600;
}
