.icon::before {
    content: '';
    position: absolute;
    right: 1.5rem;
    width: 22px;
    height: 22px;
    background-size: contain;
    background-repeat: no-repeat;
}

.question::before {
    background-image: url('./icons/icon-question-blue.png');
}

.condition::before {
    background-image: url('./icons/icon-condition-yellow.png');
}

.instructions::before {
    background-image: url('./icons/icon-instructions-yellow.png');
}

.timeScale::before {
    background-image: url('./icons/icon-timescale-green.png');
}

.responseScale::before {
    background-image: url('./icons/icon-responsescale-green.png');
}

.symptomScale::before {
    background-image: url('./icons/icon-symptomscale-green.png');
}

.questionnaire::before {
    background-image: url('./icons/icon-question-blue.png');
}

.core::before {
    background-image: url('./icons/icon-mendeleiev-core.png');
}

.module::before {
    background-image: url('./icons/icon-mendeleiev-module.png');
}

.standalone::before {
    background-image: url('./icons/icon-mendeleiev-standalone.png');
}

.previous::before {
    background-image: url('./icons/icon-mendeleiev-previous.png');
}

.cat::before {
    background-image: url('./icons/icon-mendeleiev-CAT.png');
}

.catshort::before {
    background-image: url('./icons/icon-mendeleiev-catshort.png');
}

.shortform::before {
    background-image: url('./icons/icon-mendeleiev-shortform.png');
}

.itemlist::before {
    // Not sure - should be checked!
    background-image: url('./icons/icon-mendeleiev-previous.png');
}

.draft::before {
    background-image: url('./icons/icon-mendeleiev-draft.png');
}

.male::before {
    background-image: url('./icons/icon-question-gender-male.png');
}

.female::before {
    background-image: url('./icons/icon-woman.png');
}
