@use '~styles/variables/colors';

.wrapper {
    border-radius: 3px;
    background: colors.$gray-concrete;
    padding: 2rem;
    margin: 4rem 0px;

    h4 {
        font-weight: 600;
        margin-bottom: 1rem;
        font-size: 1.8rem;
    }
}

.listWrapper {
    width: 100%;

    ul {
        list-style: none;
        padding-left: 0;
        width: 100%;

        li {
            width: 100%;
        }

        button {
            width: 100%;
            text-align: left;
            border: none;
            margin-bottom: 1px;
            color: #0472db;
            background: #f9f9f9;
            padding: 0.8rem 1.5rem;
            font-size: 1.6rem;
            font-weight: 500;
            position: relative;
        }

        .active {
            box-shadow: none;
            color: #000 !important;
            background: colors.$white  !important;
        }
    }
}

.icon::before {
    content: '';
    position: absolute;
    right: 1.5rem;
    width: 22px;
    height: 22px;
    background-size: contain;
    background-repeat: no-repeat;
}

.core:before {
    background-image: url(./icons/icon-core-sm.png);
}

.module:before {
    background-image: url(./icons/icon-module-sm.png);
}

.standalone:before {
    background-image: url(./icons/icon-standalone-sm.png);
}

.previous:before {
    background-image: url(./icons/icon-previous-sm.png);
}

.cat:before {
    background-image: url(./icons/icon-CAT-sm.png);
}

.catshort:before {
    background-image: url(./icons/icon-catshort-sm.png);
}

.shortform:before {
    background-image: url(./icons/icon-shortform.png);
}