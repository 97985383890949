@use '~styles/variables/colors';

.wrapper {
    border-top: 1px solid colors.$gray-concrete !important;
    border-bottom: 1px solid colors.$gray-concrete !important;
    margin: 0;
    background-color: colors.$white;

    > div {
        padding: 0;
    }

    a {
        display: inline-block;
        width: 100%;
        text-align: center;
        padding: 1rem 1.5rem;
        font-size: 1.8rem;
        font-weight: 500;
        text-decoration: none;
    }
}

.active {
    background-color: colors.$blue !important;
    color: colors.$white !important;
    font-weight: 600 !important;
    position: relative;
    cursor: default !important;
}
.active::before {
    content: '';
    position: absolute;
    bottom: -8px;
    width: 18px;
    left: 50%;
    transform: translate(-50%, 0);
    height: 9px;
    z-index: 100;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-top: 1rem solid colors.$blue;
}
