@use "~styles/variables/colors";

.loginWrapper {
  margin: 100px auto;

  p {
    font-weight: 300;
  }
}

.title {
  margin: 2rem 0;
  font-size: 2.4rem;
  line-height: 1.1em;
  font-weight: 600;
}

.lead {
  font-size: 2.1rem;
  margin-bottom: 2rem;
  font-weight: 300;
  line-height: 1.4em;
}

.button {
  padding: 1rem 4rem;
  font-size: 1.4rem;
  border-radius: 0.5rem;
  border-width: 2px;
  font-weight: 500;
  transition: all .2s;
  line-height: 1.33em;
}

.button:hover {
  border-color: colors.$blue-science;
  color: colors.$blue-science;
  background-color: colors.$gray-concrete;
}
